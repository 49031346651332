import CssIconComponent from '@components/icons/cssIcon';
import GitIconComponent from '@components/icons/gitIcon';
import HtmlIconComponent from '@components/icons/htmlLandingPageIcon';
import JavascriptIconComponent from '@components/icons/javscriptIcon';
import MascotBenefitsComponent from '@components/illustrations/MascotBenefits';
import PythonIconComponent from '@components/icons/pythonIcon';
import ReactIconComponent from '@components/icons/reactIcon';
import SQLIconComponent from '@components/icons/sqlIcon';
import {
  BodyText,
  Heading2,
  Heading3,
  Heading4,
  Label1,
  Subheading,
} from './Texts';
import Link from 'next/link';
import { LearnPathsConfetti } from './ConfettiBackground';
import BuildingWebAppsIconComponent from '@components/icons/onboarding/interests/buildingWebAppsIcon';
import CreatingWebPagesIconComponent from '@components/icons/onboarding/interests/creatingWebPagesIcon';
import PythonDeveloperIconComponent from '@components/icons/onboarding/interests/pythonDeveloperIcon';
import { CodingLanguage } from '@customtypes/parsedLesson';
import SwiftIconComponent from '@components/icons/swiftIcon';
import TypescriptIconComponent from '@components/icons/typescriptIcon';
import AISparksIconComponent from '@components/icons/landingpage/aiSparksIcon';
import { getRegisterPageLink } from '@utils/link-utils-web';

export default function LearnPathsComponent() {
  const languages = [
    'html',
    'css',
    'javascript',
    'typescript',
    'python',
    'sql',
    'swift',
  ] as CodingLanguage[];
  const frontendIcons = [
    <HtmlIconComponent key={'html'} />,
    <CssIconComponent key={'css'} />,
    <JavascriptIconComponent key={'js'} />,
    <ReactIconComponent key={'react'} />,
  ];

  const fullStackIcons = [
    <HtmlIconComponent key={'html'} />,
    <CssIconComponent key={'css'} />,
    <JavascriptIconComponent key={'js'} />,
    <ReactIconComponent key={'react'} />,
    <SQLIconComponent key={'sql'} />,
    <GitIconComponent key={'git'} />,
  ];
  return (
    <div className="relative z-10 space-y-4 text-text-primary-dark tablet:space-y-4">
      <LearnPathsConfetti />
      <div className="hidden tabletLg:block tabletLg:h-[160px]"></div>
      <div className="grid grid-flow-row grid-cols-1 pb-10 desktop:grid-cols-2 desktop:gap-4">
        <div className="space-y-6 text-center tablet:text-left tabletLg:w-full">
          <Heading2>Python, JavaScript, HTML, CSS, and more</Heading2>
          <Subheading className="md:w-full md:max-w-[50%] desktop:w-full desktop:max-w-full">
            Choose between a range of beginner-friendly learning paths,
            hand-crafted by learning experts and backed by research
          </Subheading>
        </div>
      </div>
      <div className="h-[160px] tablet:hidden"></div>
      <div className="grid grid-flow-row grid-cols-1 gap-4 tabletLg:gap-4 desktop:grid-cols-3">
        <div className="relative flex items-end">
          <CareerPathComponent
            title="Full-stack Development"
            description="Discover the core of both front-end and back-end development. Learn to create user interfaces and master key programming languages and frameworks driving today's web"
            headerIcon={
              <BuildingWebAppsIconComponent></BuildingWebAppsIconComponent>
            }
            icons={fullStackIcons}
          />
          <MascotBenefitsComponent className="absolute right-0 top-[-168px] h-[168px] desktop:top-[-220px] desktop:hidden desktop:h-[220px]"></MascotBenefitsComponent>
        </div>

        <div className="relative flex items-end">
          <CareerPathComponent
            title="Front-end Development"
            description="Dive into front-end development using HTML, CSS, and JavaScript. Learn to craft web pages and use React to develop engaging user-focused web applications"
            headerIcon={
              <CreatingWebPagesIconComponent></CreatingWebPagesIconComponent>
            }
            icons={frontendIcons}
          />
          <MascotBenefitsComponent className="absolute right-0 top-[-168px] hidden h-[168px] tabletLg:hidden desktop:top-[-220px] desktop:h-[220px]"></MascotBenefitsComponent>
        </div>

        <div className="relative flex items-end">
          <CareerPathComponent
            title="Python Development"
            description="Learn Python to quickly develop anything from web applications to artificial intelligence"
            headerIcon={
              <PythonDeveloperIconComponent></PythonDeveloperIconComponent>
            }
            icons={[<PythonIconComponent key={'python'} />]}
          />
          <MascotBenefitsComponent className="absolute right-0 top-[-168px] hidden h-[168px] desktop:top-[-220px] desktop:block desktop:h-[220px]"></MascotBenefitsComponent>
        </div>
      </div>
      <div className="grid auto-rows-auto grid-cols-1 gap-4 tablet:grid-cols-2 desktop:grid-cols-3">
        <AICourseCardComponent />
        {languages.map((language) => (
          <CourseCardComponent
            key={language}
            language={language}
          ></CourseCardComponent>
        ))}
      </div>
    </div>
  );
}

function CareerPathComponent({
  title,
  description,
  headerIcon,
  icons,
}: {
  title: string;
  description: string;
  headerIcon: React.JSX.Element;
  icons: Array<React.JSX.Element>;
}) {
  return (
    <Link
      href={getRegisterPageLink()}
      title="Start Learning"
      className="group relative flex h-full w-full flex-col justify-between rounded-3xl border-2 border-marketing-accent-darkBlue bg-transparent p-6 text-text-primary-dark hover:bg-marketing-surface-darkBlue tablet:p-10"
    >
      <div className="grid h-8 grid-cols-2 items-center justify-between">
        {headerIcon}
        <Label1 className="max-h-8 w-fit justify-self-end whitespace-nowrap rounded-xl bg-marketing-surface-purple px-4 py-1 uppercase">
          Career Path
        </Label1>
      </div>
      <div className="mt-10 flex grow flex-col space-y-4 tablet:space-y-6">
        <Heading3>{title}</Heading3>
        <BodyText>{description}</BodyText>
      </div>
      <div className="col-span-2 mt-10 flex h-6 max-w-[80%] flex-wrap items-start space-x-1 tablet:mt-11 tablet:h-8 tablet:max-w-none tablet:space-x-2 desktop:mt-[52px]">
        {icons}
      </div>
    </Link>
  );
}

function CourseCardComponent({ language }: { language: CodingLanguage }) {
  return (
    <Link
      href={getRegisterPageLink()}
      title="Start Learning"
      className="group flex min-h-[280px] w-full flex-col space-y-6 rounded-3xl border-2 border-marketing-accent-darkBlue bg-transparent p-6 text-text-primary-dark hover:bg-marketing-surface-darkBlue tablet:min-h-[300px] tablet:p-10 tabletLg:min-h-[308px]"
    >
      <div className="flex h-6 items-center justify-between tablet:h-8">
        {language === 'html' && <HtmlIconComponent></HtmlIconComponent>}
        {language === 'css' && <CssIconComponent></CssIconComponent>}
        {language === 'javascript' && (
          <JavascriptIconComponent></JavascriptIconComponent>
        )}
        {language === 'python' && <PythonIconComponent></PythonIconComponent>}
        {language === 'sql' && <SQLIconComponent></SQLIconComponent>}
        {language === 'swift' && <SwiftIconComponent></SwiftIconComponent>}
        {language === 'typescript' && (
          <TypescriptIconComponent></TypescriptIconComponent>
        )}
        <Label1 className="rounded-xl bg-marketing-accent-darkBlue px-4 py-1 uppercase group-hover:bg-marketing-accent-nightBlue">
          Course
        </Label1>
      </div>
      <div className="group flex flex-grow flex-col space-y-4">
        <Heading4>
          {language === 'html' && 'HTML'}
          {language === 'css' && 'CSS'}
          {language === 'javascript' && 'JavaScript'}
          {language === 'python' && 'Python'}
          {language === 'sql' && 'SQL'}
          {language === 'swift' && 'Swift'}
          {language === 'typescript' && 'TypeScript'}
        </Heading4>
        <BodyText className="flex-grow ">
          {language === 'html' &&
            'Get familiar with the foundational building blocks of web development, understand the structure of web pages, and start building real websites'}
          {language === 'css' &&
            'CSS styles the appearance and layout of web pages, making them visually appealing and responsive'}
          {language === 'javascript' &&
            'Dive into the world of JavaScript, the programming language to manipulate web page elements, build web applications, and more'}
          {language === 'python' &&
            "Explore a beginner-friendly, popular programming language that's renowned for its readability and extensive range of applications"}
          {language === 'sql' &&
            'Master SQL, an essential skill in data analysis and management, from creating to querying and manipulating databases'}
          {language === 'swift' &&
            "Apple's language for building fast and safe apps across their devices, known for its simplicity and performance"}
          {language === 'typescript' &&
            'Enhance your coding skills with TypeScript, a powerful language that adds static types to JavaScript for better code quality and scalability'}
        </BodyText>
      </div>
    </Link>
  );
}

function AICourseCardComponent() {
  return (
    <Link
      href={'https://mimo.org/courses/ai-driven-development'}
      title="Start Learning"
      className="group flex min-h-[280px] w-full flex-col space-y-6 rounded-3xl border-2 border-marketing-accent-darkBlue bg-transparent p-6 text-text-primary-dark hover:bg-marketing-surface-darkBlue tablet:min-h-[300px] tablet:p-10 tabletLg:min-h-[308px]"
    >
      <div className="flex h-6 items-center justify-between tablet:h-8">
        <AISparksIconComponent className="h-8 w-8" />
        <div className="flex items-center space-x-2">
          <Label1 className="rounded-xl bg-product2-blue-600 px-4 py-1 uppercase">
            Coming soon
          </Label1>
          <Label1 className="rounded-xl bg-marketing-accent-darkBlue px-4 py-1 uppercase group-hover:bg-marketing-accent-nightBlue">
            Course
          </Label1>
        </div>
      </div>
      <div className="group flex flex-grow flex-col space-y-4">
        <Heading4>AI-driven Development</Heading4>
        <BodyText className="flex-grow ">
          Learn how to utilize modern AI tools to become a 10x more productive
          software developer
        </BodyText>
      </div>
    </Link>
  );
}
